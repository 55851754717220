/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer,useContext } from 'react';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';
import { InventoryGlobalStyles } from './Styles/InventoryGlobalStyles';
import { searchCriteriaStyles } from './Styles/criteriaSearchStyles';
import { getSearchParams, getRadiusStore } from '../../api/user';
import { SearchInventoryObj, DisableInterface } from './inventorySearchProps';
import { ReactComponent as SearchIcon } from '../../assets/images/search-btn.svg';
import { InventorySearchContext } from '../../context/SearchInventoryContext';

export type Componentprops = {
  inventoryId?: number;
  searchClick: (
    txtValue: SearchInventoryObj['itemObject'],
    neighborstore: any
  ) => void;
  enableHyperLink: (value: any) => void;
  IsPackage?: boolean;
  throwStyle?: any;
  webLeadId?: any
};
/* eslint-disable sonarjs/cognitive-complexity */
export default function SearchFilter(props: Componentprops) {
  const classes = InventoryGlobalStyles();
  const searchCriteriaClassName = searchCriteriaStyles();
  const {
    conditionAry,
          setconditionAry,
          departmentAry,
          setdepartmentAry,
          subdepartmentAry,
          setsubdepartmentAry,
          statusType,
          setStatusType,
          neighborStore,
          setNeighborstore,
          locationRadius,
          setlocationRadius,
          TxtValues,
          setTxtValues
  } = useContext(InventorySearchContext); 
  const [statusDisabled, setstatusDisabled] = useState(true);
  // const [conditionAry, setconditionAry] = useState<any>([]);
  // const [departmentAry, setdepartmentAry] = useState<any>([]);
  // const [subdepartmentAry, setsubdepartmentAry] = useState<any>([]);
  const [radiusError, setRadiusError] = useState(false);
  const [locationDisabled, setlocationDisabled] = useState<boolean>(true);
  const [radiusErrorMsg, setradiusErrorMsg] = useState(false);
  const [conditionLoader, setconditionLoader] = useState<boolean>(true);
  const [locationLoader, setlocationLoader] = useState<boolean>(false);
  // const [neighborStore, setNeighborstore] = useState<any>([]);
  const locationtypeDropDown: any[] = [
    { label: 'Store', value: 'Store' },
    { label: 'Radius', value: 'Radius' },
  ];
  const [disable, setDisable] = useReducer(
    (
      state: DisableInterface['disable'],
      newState: Partial<DisableInterface['disable']>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      locationDisabled: true,
      radiusDisabled: false,
      locationTextDisabled: true,
      searchbtndisable: true,
      clrbtndisable: false,
    }
  );

  // const initialTxtValues: SearchInventoryObj['itemObject'] = {
  //   itemNo: '',
  //   serialNo: '',
  //   bracket: '',
  //   brand: '',
  //   model: '',
  //   poNo: '',
  //   locationTypeVal: 'Store',
  //   locationval: '986',
  //   statusId: '1',
  //   subStatusId: null,
  //   conditionId: '0',
  //   department: '',
  //   subDepartment: '',
  //   packageTypeval: '',
  //   locationNumber: '',
  //   currentStoreNumber: '',
  // };

  // const [TxtValues, setTxtValues] =
  //   useState<SearchInventoryObj['itemObject']>(initialTxtValues);
  const [locationType, setLocationType] = useState<any>([]);
  // const [statusType, setStatusType] = useState<any>([]);
  const [location, setlocation] = useState<any>();
  // const [locationRadius, setlocationRadius] = useState<any>();

  useEffect(() => {
    /* eslint-disable no-console */
    console.log('session' + TxtValues);
    const currentStore = sessionStorage.getItem('storeNumber');
    setLocationType(locationtypeDropDown);
    setstatusDisabled(false);
    if (props?.webLeadId != undefined) {
      saleFunction(props.webLeadId)
    }
    else if(departmentAry.length == 0) {
      const getSearchParams_APIcall = async () => {
        setconditionLoader(true);
        const response: any = await getSearchParams();
        setconditionLoader(false);
        console.log('buildConditionOptionRes', response);
        if (response !== undefined && response !== null) {
          const buildConditionOption = response.conditionList;
          console.log('buildConditionOption', buildConditionOption);

          const setterCondition = [
            { id: '0', referenceCode: 'ALL' },

            ...buildConditionOption,
          ];

          setconditionAry(setterCondition);

          const buildDepartOption = response.departmentList;
          const setterDepartment = [
            { id: '0', description: 'Select' },
            ...buildDepartOption,
          ];

          setdepartmentAry(setterDepartment);

          const buildSubDepartOption = response.subDepartmentList;
          const setterSubDepartment = [
            { id: '0', description: 'Select' },
            ...buildSubDepartOption,
          ];
          setsubdepartmentAry(setterSubDepartment);
          const statusOption = response.statusList;
          console.log('statusOption1', statusOption);

          const setterStatus = [
            { id: '0', referenceCode: 'ALL' },
            ...statusOption,
          ];
          setStatusType(setterStatus);
        } else {
          const setterDepartment = [{ id: '0', description: 'Select' }];
          const setterSubDepartment = [{ id: '0', description: 'Select' }];
          const setterCondition = [{ id: '0', referenceCode: 'Select' }];
          const setterStatus = [{ id: '0', referenceCode: 'Select' }];
          setconditionAry(setterCondition);
          setdepartmentAry(setterDepartment);
          setsubdepartmentAry(setterSubDepartment);
          setStatusType(setterStatus);
        }
      };
      getSearchParams_APIcall();
      const getRadiusStore_Api = async () => {
        const currentStore = sessionStorage.getItem('storeNumber');
        setlocationLoader(true);
        const response1: any = await getRadiusStore(currentStore);
        setlocationLoader(false);
        console.log('response1', response1.data.response);
        if (response1 !== undefined) {
          if (response1.status == 200) {
            const radiusStoreAry = response1.data.response;
            const neighbor: any = [];
            radiusStoreAry.map((v: any) => {
              neighbor.push(v.neighborStoreNumber);

              console.log('neighborStores', neighbor);
            });
            setNeighborstore(neighbor);
            const radiusStoreAdd = [
              { neighborStoreId: '0', neighborStoreNumber: 'Radius All' },

              ...radiusStoreAry,
            ];
            setlocationRadius(radiusStoreAdd);
          } else {
            setradiusErrorMsg(true)
          }
        }
      };
      getRadiusStore_Api()

      setTxtValues({ ...TxtValues, locationNumber: currentStore });
    }
    else{
      setconditionLoader(false);
      setTxtValues({ ...TxtValues, locationNumber: currentStore });

    }

    //setlocation([{ neighborStoreId: '0', neighborStoreNumber: currentStore }]);
    if(locationRadius && TxtValues.locationTypeVal === 'Radius'){
      setlocation(locationRadius);
      setlocationDisabled(false);
      setTxtValues({
        ...TxtValues,
        locationNumber: 'Radius All',
      });
    }
    else{
    setlocation([{ neighborStoreId: '0', neighborStoreNumber: currentStore }]);
    setlocationDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.webLeadId]);


  const saleFunction = (salesData: any) => {
    console.log('salesData', salesData);
    if (salesData.data?.inventories?.length > 0) {
      setlocation([{ neighborStoreId: '0', neighborStoreNumber: salesData.data?.storeNumber === sessionStorage.getItem('storeNumber') ? salesData.data?.storeNumber : 'Radius All' }]);
      setTxtValues({ ...TxtValues, itemNo: salesData.data.inventories[0].inventoryNumber, model: salesData.data.inventories[0].modelNumber, locationNumber: salesData.data?.storeNumber === sessionStorage.getItem('storeNumber') ? salesData.data?.storeNumber : 'Radius All', locationTypeVal: salesData.data?.storeNumber === sessionStorage.getItem('storeNumber') ? 'Store' : 'Radius' })
    }
  }
  /*
   * RF No: IS_PC_29
   * This useEffect method will validate search and clear for all fields.
   * */
  useEffect(() => {
    if (TxtValues.locationTypeVal === 'Radius') {
      setstatusDisabled(true);
      if (
        TxtValues.department !== '' ||
        TxtValues.subDepartment !== '' ||
        TxtValues.bracket.length > 0 ||
        TxtValues.brand.length > 0 ||
        TxtValues.model.length > 0 ||
        TxtValues.statusId !== '1'
      ) {
        setDisable({
          ...disable,
          searchbtndisable: false,
          clrbtndisable: true,
          radiusDisabled: false,
        });
      } else {
        setDisable({
          ...disable,
          searchbtndisable: true,
          clrbtndisable: false,
          radiusDisabled: true,
        });
      }
    } else {
      if (
        TxtValues.department !== '' ||
        TxtValues.subDepartment !== '' ||
        TxtValues.bracket.length > 0 ||
        TxtValues.brand.length > 0 ||
        TxtValues.model.length > 0 ||
        TxtValues.itemNo.length > 0 ||
        TxtValues.serialNo.length > 0 ||
        TxtValues.conditionId !== '0' ||
        TxtValues.statusId !== '1' ||
        TxtValues.poNo.length > 0
      ) {
        setDisable({
          ...disable,
          searchbtndisable: false,
          clrbtndisable: true,
        });
      } else {
        setDisable({
          ...disable,
          searchbtndisable: false,
          clrbtndisable: false,
        });
      }
    }
  }, [TxtValues]);

  /*
   * RF No: IS_PC_30
   * This method is to enter scenerio for all fields.
   * */
  const EnterOnClick = (e: any) => {
    if (
      e.key == 'Enter' &&
      (TxtValues.bracket.length > 0 ||
        TxtValues.brand.length > 0 ||
        TxtValues.model.length > 0 ||
        TxtValues.itemNo.length > 0 ||
        TxtValues.poNo.length > 0 ||
        TxtValues.serialNo.length > 0 ||
        TxtValues.conditionId !== '0' ||
        TxtValues.department !== '' ||
        TxtValues.subDepartment !== '')
    ) {
      SearchOnclick();
    }
  };
  /*
   * RF No: IS_PC_31
   * This method is to clear the values in textbox and dropdown values.
   * */
  const clearbtn = () => {
    setTxtValues({
      ...TxtValues,
      itemNo: '',
      serialNo: '',
      bracket: '',
      brand: '',
      model: '',
      poNo: '',
      locationval: '986',
      locationNumber: TxtValues.locationTypeVal === "Store" ? sessionStorage.getItem('storeNumber') : 'Radius All',
      subStatusId: TxtValues.locationTypeVal === "Store" ? null : [1, 2],
      statusId: '1',
      department: '',
      subDepartment: '',
      currentStoreNumber: '',
      conditionId: '0',
    });
    setDisable({ ...disable, searchbtndisable: true, clrbtndisable: false });
    setstatusDisabled(false);
  };
  /*
   * RF No: IS_PC_32
   * This method is passing the values to the ApiLayer to get the filtered data from database.
   * */
  const SearchOnclick = () => {
    (document.getElementById('a11y_Item') as HTMLInputElement).blur();
    (document.getElementById('a11y_bracket') as HTMLInputElement).blur();
    (document.getElementById('a11y_Model') as HTMLInputElement).blur();
    (document.getElementById('a11y_Brand') as HTMLInputElement).blur();
    (document.getElementById('a11y_Serial') as HTMLInputElement).blur();
    props.enableHyperLink(TxtValues.locationTypeVal);
    props.searchClick(TxtValues, neighborStore);
    /* eslint-disable no-console */
    console.log('TxtValues', TxtValues);
  };

  const optionsArrayFunction = () => {
    if (departmentAry !== undefined) {
      return departmentAry.map((entry: any) => {
        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const conditionArrayFunction = () => {
    if (conditionAry !== undefined) {
      return conditionAry.map((value: any, index: any) => {
        console.log('Counting log', index);
        let displayvalue: any;
        if (index >= 0) {
          if (value.referenceCode == 'EXCP') {
            displayvalue = 'Exception';
          } else if (value.referenceCode == 'ATO') {
            displayvalue = 'Auto';
          } else if (value.referenceCode == 'NEW') {
            displayvalue = 'New';
          } else if (value.referenceCode == 'ALL') {
            displayvalue = 'All';
          }
          return { label: displayvalue, value: value.id.toString() };
        }
      });
    } else {
      return [{ label: 'All', value: '0' }];
    }
  };
  const statusTypeArrayFunction = () => {
    if (statusType !== undefined) {
      return statusType.map((value: any, index: any) => {
        console.log('Counting log', index);
        let displayvalue: any;
        if (index >= 0) {
          if (value.referenceCode == 'IDLE') {
            displayvalue = 'Stock Idle';
          } else if (value.referenceCode == 'OR') {
            displayvalue = 'On Rent';
          } else if (value.referenceCode == 'CO') {
            displayvalue = 'Charged Off';
          } else if (value.referenceCode == 'ALL') {
            displayvalue = 'All';
          }
          return { label: displayvalue, value: value.id.toString() };
        }
      });
    } else {
      return [{ label: 'All', value: '0' }];
    }
  };

  const locationArrayFunction = () => {
    if (location !== undefined) {
      return location.map((value: any) => {
        return {
          label: value.neighborStoreNumber,
          value: value.neighborStoreNumber,
        };
      });
    } else {
      return [{ label: 'Radius All', value: 'Radius All' }];
    }
  };
  const locationTypeArrayFunction = () => {
    if (locationType !== undefined) {
      return locationType.map((entry: any) => {
        return { label: entry.label, value: entry.label };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const subDepartmentArrayFunction = () => {
    if (subdepartmentAry !== undefined) {
      return subdepartmentAry.map((entry: any) => {
        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };


  const handleOnChangeSelect = (e: any) => {
    const currentStore = sessionStorage.getItem('storeNumber');
    console.log('select', e.target.name);
    const FieldName = e.target.name;
    const InputValue = e.target.value;
    if (InputValue == 'Radius') {
      if(radiusErrorMsg){setRadiusError(true)}
      setlocation(locationRadius)
      setstatusDisabled(true);
      setDisable({ ...disable, locationDisabled: false, radiusDisabled: true });
      setlocationDisabled(false);
      setTxtValues({
        ...TxtValues,
        [FieldName]: InputValue,
        statusId: '1',
        subStatusId: [1, 2],
        locationNumber: 'Radius All',
        bracket: '',
        brand: '',
        itemNo: '',
        serialNo: '',
        poNo: '',
        model: '',
        conditionId: '0',
        department: '',
        subDepartment: '',
      });
    } else if (InputValue == 'Store') {
      setRadiusError(false);
      setlocation([
        { neighborStoreId: '0', neighborStoreNumber: currentStore },
      ]);
      setTxtValues({
        ...TxtValues,
        [FieldName]: InputValue,
        statusId: '1',
        locationNumber: currentStore,
        bracket: '',
        brand: '',
        itemNo: '',
        serialNo: '',
        poNo: '',
        model: '',
        conditionId: '0',
        department: '',
        subDepartment: '',
      });
      setDisable({ ...disable, locationDisabled: true, radiusDisabled: false });
      setlocationDisabled(true);
      setstatusDisabled(false);
    } else {
      setTxtValues({ ...TxtValues, [FieldName]: InputValue });
    }
  };
  const handleOnChangeTxtbox = (e: any) => {
    const FieldName = e.target.name;
    const InputValue = e.target.value;
    setTxtValues({ ...TxtValues, [FieldName]: InputValue });
  };

  return (
    <React.Fragment>
      <Grid container className={classes.mt2}>
        <Grid item md={12}>
          <Typography className={classes.title} variant="h6">
            Search Criteria
          </Typography>

          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={2}>
                  <RACSelect
                    classes={{ nativeSelect: classes.selectBoxFont }}
                    inputLabel="Location Type"
                    name="locationTypeVal"
                    options={locationTypeArrayFunction()}
                    defaultValue={TxtValues.locationTypeVal}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.name = 'locationTypeVal';
                      handleOnChangeSelect(e);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACSelect
                    classes={{ nativeSelect: classes.selectBoxFont }}
                    inputLabel="Location"
                    loading={locationLoader}
                    name="locationDD"
                    options={locationArrayFunction()}
                    //isDisabled={disable.locationDisabled}
                    isDisabled={locationDisabled}
                    defaultValue={
                      TxtValues.locationNumber == ''
                        ? 'Radius All'
                        : TxtValues.locationNumber
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setTxtValues({
                        ...TxtValues,
                        locationNumber: e.target.value,
                      });
                    }}
                    {...(radiusError === true && {
                      errorMessage: 'Unable to fetch data!',
                    })}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    inputlabel="Item #"
                    inputLabelClassname={classes.formLabel}
                    id="a11y_Item"
                    type="text"
                    name="itemNo"
                    isCurrency={false}
                    maxlength={13}
                    disabled={disable.radiusDisabled}
                    value={TxtValues.itemNo}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    inputlabel="Serial #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Serial"
                    name="serialNo"
                    disabled={disable.radiusDisabled}
                    isCurrency={false}
                    value={TxtValues.serialNo}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                    maxlength={20}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACSelect
                    classes={{ nativeSelect: classes.selectBoxFont }}
                    inputLabel="Status"
                    loading={conditionLoader}
                    options={statusTypeArrayFunction()}
                    isDisabled={statusDisabled}
                    defaultValue={
                      TxtValues.statusId !== null ? TxtValues.statusId : '0'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setTxtValues({
                        ...TxtValues,
                        statusId: e.target.value.toString(),
                      });
                    }}
                    {...(statusType.length == 1 &&
                      !conditionLoader && {
                      errorMessage: 'Unable to fetch Data',
                    })}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACSelect
                    classes={{ nativeSelect: classes.selectBoxFont }}
                    inputLabel="Condition"
                    loading={conditionLoader}
                    options={conditionArrayFunction()}
                    defaultValue={
                      TxtValues.conditionId !== null
                        ? TxtValues.conditionId
                        : '0'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.name = 'conditionId';
                      handleOnChangeSelect(e);
                    }}
                    {...(conditionAry.length == 1 &&
                      !conditionLoader && {
                      errorMessage: 'Unable to fetch Data',
                    })}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACSelect
                    classes={{ nativeSelect: classes.selectBoxFont }}
                    inputLabel="Department"
                    loading={conditionLoader}
                    options={optionsArrayFunction()}
                    defaultValue={
                      TxtValues.department !== ''
                        ? TxtValues.department
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                          ...TxtValues,
                          department: '',
                        })
                        : setTxtValues({
                          ...TxtValues,
                          department: e.target.value,
                        });
                    }}
                    {...(departmentAry.length == 1 &&
                      !conditionLoader && {
                      errorMessage: 'Unable to fetch Data',
                    })}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACSelect
                    classes={{ nativeSelect: classes.selectBoxFont }}
                    inputLabel="Sub Department"
                    loading={conditionLoader}
                    options={subDepartmentArrayFunction()}
                    defaultValue={
                      TxtValues.subDepartment !== ''
                        ? TxtValues.subDepartment
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                          ...TxtValues,
                          subDepartment: '',
                        })
                        : setTxtValues({
                          ...TxtValues,
                          subDepartment: e.target.value,
                        });
                    }}
                    {...(subdepartmentAry.length == 1 &&
                      !conditionLoader && {
                      errorMessage: 'Unable to fetch Data',
                    })}
                  />
                </Grid>

                <Grid item md={2}>
                  <RACTextbox
                    inputlabel="Bracket"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_bracket"
                    name="bracket"
                    isCurrency={false}
                    value={TxtValues.bracket}
                    maxlength={50}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    inputlabel="Brand"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Brand"
                    name="brand"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.brand}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={2}>
                  <RACTextbox
                    inputlabel="Model #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Model"
                    name="model"
                    isCurrency={false}
                    // disabled={disable.radiusDisabled}
                    disabled={false}
                    maxlength={50}
                    value={TxtValues.model}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    inputlabel="PO #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_po"
                    name="poNo"
                    isCurrency={false}
                    disabled={disable.radiusDisabled}
                    value={TxtValues.poNo}
                    // maxlength={20}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.mt2}>
                <Grid item md={12}>
                  <Grid className={`${classes.floatRight} ${classes.mt4}`}>
                    {disable.clrbtndisable ? (
                      <a
                        className={`${classes.racpadLink} ${classes.mt2} ${classes.me4}`}
                        data-testid="ClearBtn"
                        onClick={clearbtn}
                      >
                        Clear
                      </a>
                    ) : null}{' '}
                    <RACButton
                      variant="contained"
                      color="primary"
                      className={searchCriteriaClassName.searchBtn}
                      size="large"
                      onClick={() => SearchOnclick()}
                      disabled={disable.searchbtndisable}
                    >
                      <SearchIcon className={classes.me1} />
                      Search
                    </RACButton>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
