/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable sonarjs/no-duplicate-string */

import React from 'react';
import { formatter, validateData } from '../../constants/CommonMethods';
// import { PageStyles } from './PageStyles';
import { formStyles } from '../../components/componentstyles/formstyles';
export interface itemPrintProps {
  printItemNo: any[];
  printLanguage: string;
  printItemResp: any;
  invRefCodes?: any;
}
const Barcode = require('react-barcode');
const sprintf = require('sprintf-js').sprintf;
export default function PrintFunction(props: itemPrintProps) {
  const formClasses = formStyles();
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodestore = Buffer.from(storeDetails, 'base64');
  const decodestore: any = JSON.parse(encodestore.toString('ascii'));
  console.log('inside Print Function', props);
  console.log('decodestore', decodestore);

  const data = validateData(props.printItemResp) ? props.printItemResp : [];
  console.log('responsedata', data);
  const PriceTag = () => {
    return props.printItemNo.map((values: any, index: any) => {
      // console.log('generateBarCodeContent',generateBarCodeContent(data[index].inventoryPriceId, props?.invRefCodes?.depRefCode, props?.invRefCodes?.subDepRefCode));subDeptRefcode

      if (
        validateData(data[index]) &&
        validateData(data[index].weeklyRate) &&
        validateData(data[index].brand)
      ) {
        const costRental = Number(data[index].totalCost) - Number(data[index].sameAsCashPrice);
        // const SplitweeklyRate = data[index].weeklyRate.split('.');
        const SplitweeklyRate = Number(data[index]?.weeklyRate)?.toFixed(2)?.split('.');
        const brand = data[index].brand.toUpperCase();
        const monthmain = (Number(data[index].totalCost)) / (Number(data[index].monthlyRate))
        if (data[index]?.templateName == "SingleItemPriceTag_new") {
          return (

            <div key={index} style={{ fontFamily: 'Arial', marginTop: '0% important' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '25px', textAlign: 'center', paddingTop: index > 0 ? '20px' : '' }}>
                  {validateData(brand) ? brand : '-'}
                </h1>{

                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '27px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', display: 'inline-block' }}>
                      <span style={{ fontSize: '70px', fontWeight: 'bold', position: 'relative', top: '-44px' }}>$</span>
                      <span style={{ fontSize: '138px', fontWeight: 'bold' }}>
                        {validateData(SplitweeklyRate[0]) ? SplitweeklyRate[0] : ' - '}
                      </span>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: '8px' }}>
                      <p style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '10px', marginTop: '15px' }}>
                        {validateData(SplitweeklyRate[1]) ? SplitweeklyRate[1] : ' - '}
                      </p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>PER WEEK</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : '-'}{' '}
                        Payments</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>to Own It</p>
                    </div>
                  </div>
                </div>

                <p style={{ fontSize: '21px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '24px', marginBottom: '10px' }}>Additional Flexible Payment Options:</p>
                <div style={{ width: '100%', float: 'left', marginBottom: '15px' }}>
                  <div style={{ width: '33%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Bi-weekly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter.format(data[index].biWeeklyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? (data[index].biWeeklyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Semi-monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter.format(data[index].semiMonthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,
                      </span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? (data[index].semiMonthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter.format(data[index].monthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? (data[index].monthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <p style={{ fontSize: '22px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '10px', marginBottom: '6px' }}>Easy Ownership Options:</p>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '18px' }}>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) &&
					        Number(data[index].sacDaysToPrint) > 0 ? (
                    <span style={{ fontWeight: "bold" }}>
                    {data[index].sacDaysToPrint} Days&nbsp;
                    </span>
                  ) : null}
                  <span style={{ fontWeight: 'bold' }}>Same As Cash Price (SAC):&nbsp;</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '18px' }}>
                  <span style={{ fontWeight: 'bold' }}>After SAC:&nbsp;</span>
                  <span><span style={{ fontWeight: 'bold' }}>{data[index].epoPercentage} % </span> off remaining total</span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: data[index]?.inventoryDescription?.length <= 62 ? '40px' : '48px', fontSize: '18px' }}>
                  <span style={{ fontWeight: 'bold' }}>Total Cost: </span>
                  <span>

                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter.format(data[index].weeklyRate).replace(/^(\D+)/, '$1 ')
                      : '-'}{' '}
                    x{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : '-'}{' '}
                  </span>
                  <span>payments =</span>
                  <span>{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter.format(data[index].totalCost).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                   
                <div style={{ width: '100%', textAlign: 'center'}}>
                  
                  <p style={{ textAlign: 'center', fontSize: '22px', marginBottom: '23px', marginTop: '28px', marginLeft: '13px' }}>
                    <span style={{height:"14px", display:'block'}}></span>
                   <span style={{display:'block'}}> {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : '-'}{'  '}
                    {/* Previously Rented */}</span>
                  </p>
                  <div className="text-align:center;width:100%" style={{ marginTop: '3px' }}>
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: '12px' }}>Item: </span>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: '12px' }}>Model: </span>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : '-'}
                        {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: '12px' }}>Serial: </span>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix} >
                    <Barcode width={1.5} value={generateBarCodeContent(data[index].inventoryPriceId, data[index].deptRefcode, data[index].subDeptRefcode)} displayValue={true} height={48} fontSize={7} format='CODE128' />{' '}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        else if (data[index]?.templateName == "SingleItemPriceTag_n2") {
          return (
            <div
              key={index}
              style={{
                fontFamily: "Arial",
                marginTop: "0% important",
              }}
            >
              <div style={{ width: "100%" }}>
                <h1
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    paddingTop: index > 0 ? "20px" : '',
                  }}
                >
                  {validateData(brand) ? brand : "-"}
                </h1>
                {

                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '27px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{ textAlign: "center", display: "inline-block" }}
                    >
                      <span
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          position: "relative",
                          top: "-44px",
                        }}
                      >
                        $
                      </span>
                      <span style={{ fontSize: "138px", fontWeight: "bold" }}>
                        {validateData(SplitweeklyRate[0])
                          ? SplitweeklyRate[0]
                          : " - "}
                      </span>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "8px" }}>
                      <p
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        {validateData(SplitweeklyRate[1])
                          ? SplitweeklyRate[1]
                          : " - "}
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        PER WEEK
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : "-"}{" "}
                        Payments
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        to Own It
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "21px",
                    marginBottom: "10px",
                  }}
                >
                  Additional Flexible Payment Options:
                </p>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "15px" }}
                >
                  <div
                    style={{
                      width: "33%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Bi-weekly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter
                            .format(data[index].biWeeklyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? data[index].biWeeklyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "32%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Semi-monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter
                            .format(data[index].semiMonthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? data[index].semiMonthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "32%", float: "left" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter
                            .format(data[index].monthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? data[index].monthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "9px",
                    marginBottom: "6px",
                  }}
                >
                  Easy Ownership Options:
                </p>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "7px",
                    fontSize: "17px",
                  }}
                >

                  {validateData(data[index]) && validateData(data[index].sacDaysToPrint) &&
					        Number(data[index].sacDaysToPrint) > 0 ? (
                    <span style={{ fontWeight: "bold" }}>
                    {data[index].sacDaysToPrint} Days&nbsp;
                    </span>
                  ) : null}
                  <span style={{ fontWeight: "bold" }}>
                    Same As Cash Price (SAC):&nbsp;
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter
                        .format(data[index].sameAsCashPrice)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "7px",
                    fontSize: "16px",
                    marginLeft: "3px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>After SAC:&nbsp;</span>
                  <span>
                    <span style={{ fontWeight: "bold" }}></span>Purchase early
                    to save on the total cost
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "53px",
                    fontSize: "17px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Total Cost: </span>
                  <span>
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter
                        .format(data[index].weeklyRate)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}{" "}
                    x{" "}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : "-"}{" "}
                  </span>
                  <span>payments =</span>
                  <span>
                    {" "}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter
                        .format(data[index].totalCost)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginBottom: "30px",
                      marginTop: "42px",
                      marginLeft: "13px",
                    }}
                  >
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : "-"}
                    {"  "}
                    {/* Previously Rented */}
                  </p>
                  <div className="text-align:center;width:100%">
                    {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: "12px" }}>Item: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Model: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Serial: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : "-"}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix}>
                    <Barcode width={1.5}
                      value={generateBarCodeContent(
                        data[index].inventoryPriceId,
                        data[index].deptRefcode,
                        data[index].subDeptRefcode
                      )}
                      displayValue={true}
                      height={48}
                      fontSize={7}
                      format="CODE128"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else if (data[index]?.templateName == "MESingleItemPriceTag_n") {
          return (
            <div
              key={index}
              style={{ fontFamily: "Arial", marginTop: "0% important" }}
            >
              <div style={{ width: "100%" }}>
                <h1
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    paddingTop: index > 0 ? '20px' : ''
                  }}
                >
                  {validateData(brand) ? brand : "-"}
                </h1>
                {

                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '27px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{ textAlign: "center", display: "inline-block" }}
                    >
                      <span
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          position: "relative",
                          top: "-44px",
                        }}
                      >
                        $
                      </span>
                      <span style={{ fontSize: "138px", fontWeight: "bold" }}>
                        {validateData(SplitweeklyRate[0])
                          ? SplitweeklyRate[0].length >= 2 ? SplitweeklyRate[0] : (SplitweeklyRate[0] + ' ')
                          : " - "}
                      </span>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "8px" }}>
                      <p
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        {validateData(SplitweeklyRate[1])
                          ? SplitweeklyRate[1]
                          : " - "}
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        PER WEEK
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : "-"}{" "}
                        Payments
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        + Purchase Option
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginBottom: "9px",
                  }}
                >
                  Additional Flexible Payment Options:
                </p>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "15px" }}
                >
                  <div
                    style={{
                      width: "33%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Bi-weekly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter
                            .format(data[index]?.biWeeklyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? data[index].biWeeklyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                      <div
                        style={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        + Purchase Option
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "32%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Semi-monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter
                            .format(data[index].semiMonthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? data[index].semiMonthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                      <div
                        style={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        + Purchase Option
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "32%", float: "left" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter
                            .format(data[index].monthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? data[index].monthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                      <div
                        style={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        + Purchase Option
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    ></div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    // marginTop: "6px",
                    marginBottom: "6px",
                  }}
                >
                  Easy Ownership Options:
                </p>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // marginBottom: "4px",
                    fontSize: "16px",
                  }}
                >
                  <span>
                    <span style={{ fontWeight: "bold" }}>Cash Price:</span>
                    <span style={{ fontWeight: "bold" }}>
                      {validateData(data[index]) &&
                        validateData(data[index].sameAsCashPrice)
                        ? formatter
                          .format(data[index].sameAsCashPrice)
                          .replace(/^(\D+)/, "$1 ")
                        : "-"}
                    </span>
                    &nbsp;
                  </span>
                  <span style={{ fontSize: "15px" }}>
                    Same As Cash Price (SAC) + Purchase Option
                  </span>
                </div>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) && Number(data[index].sacDaysToPrint) > 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {data[index].sacDaysToPrint}&nbsp;
                    </span>
					          <span style={{ fontWeight: "bold" }}>
                      Days SAC
                    </span>
                  </div>
                )}              
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // marginBottom: "4px",
                    fontSize: "16px",
                    // marginTop: "35px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>After SAC:&nbsp;</span>
                  <span>Purchase early to save&nbsp;</span>
                  <span>
                    <span style={{ fontWeight: "bold" }}>{data[index].epoPercentage} % off</span>{" "}
                    remaining rent
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // marginBottom: "36px",
                    fontSize: "16px",
                    // marginTop: "3px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Total Rental Cost:{" "}
                  </span>
                  <span>
                    {" "}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter
                        .format(data[index].totalCost)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                  <span> + </span>
                  <div style={{ marginTop: "4px", marginBottom: '32px' }}>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      End-of-Lease Purchase Option &nbsp;
                    </span>
                    <span>
                      {validateData(data[index]) &&
                        validateData(data[index].totalCost)
                        ? formatter
                          .format(data[index].totalCost)
                          .replace(/^(\D+)/, "$1 ")
                        : "-"}
                    </span>
                  </div>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginBottom: "30px",
                      marginTop: "27px",
                    }}
                  >
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : "-"}
                    {"  "}
                  </p>
                  <div className="text-align:center;width:100%">
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: "12px" }}>Item: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Model: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Serial: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : "-"}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix}>
                    <Barcode
                      width={1.5}
                      value={generateBarCodeContent(
                        data[index].inventoryPriceId,
                        data[index].deptRefcode,
                        data[index].subDeptRefcode
                      )}
                      displayValue={true}
                      height={48}
                      fontSize={7}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        else if (data[index]?.templateName == 'NCSingleItemPriceTag_n') {
          return (
            <div
              key={index}
              style={{ fontFamily: "Arial", marginTop: "0% important" }}
            >
              <div style={{ width: "100%" }}>
                <h1
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    paddingTop: index > 0 ? '20px' : '',
                  }}
                >
                  {validateData(brand) ? brand : "-"}
                </h1>
                {

                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '27px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{ textAlign: "center", display: "inline-block" }}
                    >
                      <span
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          position: "relative",
                          top: "-44px",
                        }}
                      >
                        $
                      </span>
                      <span style={{ fontSize: "138px", fontWeight: "bold" }}>
                        {validateData(SplitweeklyRate[0])
                          ? SplitweeklyRate[0]?.length >= 2 ? SplitweeklyRate[0] : (SplitweeklyRate[0] + ' ')
                          : " - "}
                      </span>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "8px" }}>
                      <p
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        {validateData(SplitweeklyRate[1])
                          ? SplitweeklyRate[1]
                          : " - "}
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        PER WEEK
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : "-"}{" "}
                        Payments
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        + Purchase Option
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginBottom: "9px",
                  }}
                >
                  Additional Flexible Payment Options:
                </p>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "15px" }}
                >
                  <div
                    style={{
                      width: "33%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Bi-weekly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter
                            .format(data[index]?.biWeeklyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? data[index].biWeeklyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                      <div
                        style={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        + Purchase Option
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "32%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Semi-monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter
                            .format(data[index].semiMonthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? data[index].semiMonthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                      <div
                        style={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        + Purchase Option
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "32%", float: "left" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter
                            .format(data[index].monthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? data[index].monthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                      <div
                        style={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        + Purchase Option
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    ></div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    // marginTop: "6px",
                    marginBottom: "6px",
                  }}
                >
                  Easy Ownership Options:
                </p>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // marginBottom: "4px",
                    fontSize: "16px",
                  }}
                >
                  <span>
                    <span style={{ fontWeight: "bold" }}>Cash Price:</span>
                    <span style={{ fontWeight: "bold" }}>
                      {validateData(data[index]) &&
                        validateData(data[index].sameAsCashPrice)
                        ? formatter
                          .format(data[index].sameAsCashPrice)
                          .replace(/^(\D+)/, "$1 ")
                        : "-"}
                    </span>
                    &nbsp;
                  </span>
                  <span style={{ fontSize: "15px" }}>
                    Same As Cash Price (SAC) + Purchase Option
                  </span>
                </div>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) && Number(data[index].sacDaysToPrint) > 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      //marginBottom: "7px",
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {data[index].sacDaysToPrint}&nbsp;
                    </span>
					          <span style={{ fontWeight: "bold" }}>
                      Days SAC
                    </span>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // marginBottom: "4px",
                    fontSize: "16px",
                    // marginTop: "35px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>After SAC:&nbsp;</span>
                  <span>Purchase early to save&nbsp;</span>
                  <span>
                    <span style={{ fontWeight: "bold" }}>{data[index].epoPercentage} % off</span>{" "}
                    remaining rent
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    // marginBottom: "36px",
                    fontSize: "16px",
                    // marginTop: "3px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Total Rental Cost:{" "}
                  </span>
                  <span>
                    {" "}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter
                        .format(data[index].totalCost)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                  <span> + </span>
                  <div style={{ marginTop: "4px", marginBottom: '23px' }}>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      End-of-Lease Purchase Option &nbsp;
                    </span>
                    <span>
                      {validateData(data[index]) &&
                        validateData(data[index]?.noteTotalCost)
                        ? formatter
                          .format(data[index].noteTotalCost)
                          .replace(/^(\D+)/, "$1 ")
                        : "-"}
                    </span>
                  </div>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginBottom: "30px",
                      marginTop: "20px",
                    }}
                  >
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : "-"}
                    {"  "}
                  </p>
                  <div className="text-align:center;width:100%">
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: "12px" }}>Item: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Model: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Serial: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : "-"}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix}>
                    <Barcode
                      width={1.5}
                      value={generateBarCodeContent(
                        data[index].inventoryPriceId,
                        data[index].deptRefcode,
                        data[index].subDeptRefcode
                      )}
                      displayValue={true}
                      height={48}
                      fontSize={7}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        else if (data[index]?.templateName == "IASingleItemPriceTag_n") {
          return (
            <div key={index}
              style={{ fontFamily: 'Arial', marginTop: '0% important' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '25px', textAlign: 'center', paddingTop: index > 0 ? '20px' : '', }}>
                  {validateData(brand) ? brand : '-'}
                </h1>
                {
                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '26px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', display: 'inline-block' }}>
                      <span style={{ fontSize: '70px', fontWeight: 'bold', position: 'relative', top: '-44px' }}>$</span>
                      <span style={{ fontSize: '136px', fontWeight: 'bold' }}>
                        {validateData(SplitweeklyRate[0]) ? SplitweeklyRate[0] : ' - '}
                      </span>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: '8px' }}>
                      <p style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '10px', marginTop: '15px' }}>
                        {validateData(SplitweeklyRate[1]) ? SplitweeklyRate[1] : ' - '}
                      </p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>PER WEEK</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : '-'}{' '}
                        Payments</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>to Own It</p>
                    </div>
                  </div>
                </div>

                <p style={{ fontSize: '19px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '17px', marginBottom: '8px' }}>Additional Flexible Payment Options:</p>
                <div style={{ width: '100%', float: 'left', marginBottom: '15px', borderBottom: '1px solid #000' }}>
                  <div style={{ width: '33%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Bi-weekly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter.format(data[index].biWeeklyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? (data[index].biWeeklyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Semi-monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter.format(data[index].semiMonthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,
                      </span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? (data[index].semiMonthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter.format(data[index].monthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? (data[index].monthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <p style={{ fontSize: '17px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginBottom: '5px' }}>Easy Ownership Options:</p>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '4px', fontSize: '16px' }}>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) &&
					        Number(data[index].sacDaysToPrint) > 0 ? (
                    <span style={{ fontWeight: "bold" }}>
                    {data[index].sacDaysToPrint} Days&nbsp;
                    </span>
                  ) : null}
                  <span style={{ fontWeight: 'bold' }}>Same As Cash Price (SAC):&nbsp;</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '6px', fontSize: '16px' }}>
                  <span style={{ fontWeight: 'bold' }}>After SAC:&nbsp;</span>
                  <span><span>Purchase early to</span> save on the total cost.</span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '33px', fontSize: '17px' }}>
                  <span style={{ fontWeight: 'bold' }}>Total Cost: </span>
                  <span>

                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter.format(data[index].weeklyRate).replace(/^(\D+)/, '$1 ')
                      : '-'}{' '}
                    x{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : '-'}{' '}
                  </span>
                  <span>payments =</span>
                  <span>{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter.format(data[index].totalCost).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                  <div style={{ border: '1px solid #000' }}></div>
                  <div style={{ textAlign: 'left' }}>
                    <span style={{ fontSize: '11px' }}>This is consumer rental purchase agreement. You will not own the property untill the total cost paid in full or you exercise your early purchase option as provided by lowa law.</span>
                  </div>
                </div>

                <div style={{ width: '100%', textAlign: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', marginBottom: '29px', marginTop: '19px' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : '-'}{'  '}
                    {/* Previously Rented */}
                  </p>
                  <div className="text-align:center;width:100%" style={{ fontSize: '12px' }}>
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span>Item: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Model: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : '-'}
                        {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Serial: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix} >
                    <Barcode width={1.5} value={generateBarCodeContent(data[index].inventoryPriceId, data[index].deptRefcode, data[index].subDeptRefcode)} displayValue={true} height={48} fontSize={7} />{' '}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else if (data[index]?.templateName == "PRSingleItemPriceTag_n_es") {
          return (
            <div
              key={index}
              style={{ fontFamily: "Arial", marginTop: "0% important" }}
            >
              <div style={{ width: "100%" }}>
                <h1
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    paddingTop: index > 0 ? "20px" : '',
                    marginRight: "70px",
                  }}
                >
                  {validateData(brand) ? brand : "-"}
                </h1>
                {

                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '27px', fontSize: '15px', marginRight: "71px" }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px', marginRight: "71px" }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{ textAlign: "center", display: "inline-block" }}
                    >
                      <span
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          position: "relative",
                          top: "-44px",
                        }}
                      >
                        $
                      </span>
                      <span style={{ fontSize: "138px", fontWeight: "bold" }}>
                        {validateData(SplitweeklyRate[0])
                          ? SplitweeklyRate[0]
                          : " - "}
                      </span>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "8px" }}>
                      <p
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        {validateData(SplitweeklyRate[1])
                          ? SplitweeklyRate[1]
                          : " - "}
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        SEMANALES*
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : "-"}{" "}
                        pagos para
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        que sea tuyo
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    fontSize: "21px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "24px",
                    marginBottom: "10px",
                  }}
                >
                  más opciones de pagos flexibles:
                </p>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "15px",
                    // borderBottom: "1px solid #000",
                  }}
                >
                  <div
                    style={{
                      width: "33%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Bisemanales
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter
                            .format(data[index].biWeeklyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}*
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? data[index].biWeeklyTerm
                          : "-"}{" "}
                        pagos
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "32%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Quincenales
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter
                            .format(data[index].semiMonthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}*
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? data[index].semiMonthlyTerm
                          : "-"}{" "}
                        pagos
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "32%", float: "left" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Mensuales
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter
                            .format(data[index].monthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}*
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? data[index].monthlyTerm
                          : "-"}{" "}
                        pagos
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "6px",
                  }}
                >
                  Opciones sencillas para adquirir el derecho de propiedad
                </p>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "5px",
                    fontSize: "18px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Precio en efectivo:&nbsp;</span>
                  <span style={{ fontWeight: "bold" }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter
                        .format(data[index].sameAsCashPrice)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}*
                  </span>
                </div>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) && Number(data[index].sacDaysToPrint) > 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      //marginBottom: "7px",
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {data[index].sacDaysToPrint}&nbsp;
                    </span>
					          <span style={{ fontWeight: "bold" }}>
                      Días de precio en efectivo
                    </span>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "5px",
                    fontSize: "17px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>50% de descuento:&nbsp;</span>
                  <span>
                    <span>del saldo descuento</span>
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "45px",
                    fontSize: "17px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Precio total: </span>
                  <span>
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter
                        .format(data[index].weeklyRate)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}{" "}
                    x{" "}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : "-"}{" "}
                  </span>
                  <span>pagos =</span>
                  <span>
                    {" "}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter
                        .format(data[index].totalCost)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}*
                  </span>
                </div>

                <div style={{ width: "100%", textAlign: "center" }}>
                  {data != undefined && data[index].status == 'Previously Rented' ?
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "22px",
                        fontWeight: "bold",
                        marginBottom: "28px",
                        marginTop: "14px",
                      }}
                    >
                      Previamente Alquilado
                    </p> :
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "22px",
                        fontWeight: "bold",
                        marginBottom: "25px",
                        marginTop: "20px",
                      }}
                    >
                      Nuevo
                    </p>}
                  <div style={{ fontSize: '9px' }}>Impuestos No Includes*</div>
                  <div className="text-align:center;width:100%" style={{ fontSize: '7px' }}>
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: "8px" }}>Articulo: </span>
                      <span style={{ fontSize: "8px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "8px" }}>Modelo: </span>
                      <span style={{ fontSize: "8px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "8px" }}>Serie: </span>
                      <span style={{ fontSize: "8px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : "-"}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix} style={{ marginTop: '-2px' }}>
                    <Barcode
                      width={1.5}
                      value={generateBarCodeContent(
                        data[index].inventoryPriceId,
                        data[index].deptRefcode,
                        data[index].subDeptRefcode
                      )}
                      displayValue={true}
                      format={"CODE128"}
                      height={48}
                      fontSize={7}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else if (data[index]?.templateName == "PASingleItemPriceTag_n") {
          return (
            <div key={index}
              style={{ fontFamily: 'Arial', marginTop: '0% important' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '25px', textAlign: 'center', paddingTop: index > 0 ? '22px' : '' }}>
                  {validateData(brand) ? brand : '-'}
                </h1>
                {
                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '27px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', display: 'inline-block' }}>
                      <span style={{ fontSize: '70px', fontWeight: 'bold', position: 'relative', top: '-44px' }}>$</span>
                      <span style={{ fontSize: '138px', fontWeight: 'bold' }}>
                        {validateData(SplitweeklyRate[0]) ? SplitweeklyRate[0] : ' - '}
                      </span>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: '8px' }}>
                      <p style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '10px', marginTop: '15px' }}>
                        {validateData(SplitweeklyRate[1]) ? SplitweeklyRate[1] : ' - '}
                      </p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>PER WEEK</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : '-'}{' '}
                        Payments</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>to Own It</p>
                    </div>
                  </div>
                </div>

                <p style={{ fontSize: '19px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '22px', marginBottom: '8px' }}>Additional Flexible Payment Options:</p>
                <div style={{ width: '100%', float: 'left', marginBottom: '15px' }}>
                  <div style={{ width: '33%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Bi-weekly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter.format(data[index].biWeeklyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? (data[index].biWeeklyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Semi-monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter.format(data[index].semiMonthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,
                      </span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? (data[index].semiMonthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter.format(data[index].monthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? (data[index].monthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '18px' }}>
                  <span style={{ fontWeight: 'bold' }}>Cost of Lease Services:&nbsp;</span>
                  <span> {validateData(data[index]) &&
                    validateData(data[index].costOfRental)
                    ? formatter.format(data[index].costOfRental).replace(/^(\D+)/, '$1 ')
                    : '-'}</span>
                </div>
                <p style={{ fontSize: '18px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}>Easy Ownership Options:</p>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '17px' }}>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) &&
					        Number(data[index].sacDaysToPrint) > 0 ? (
                    <span style={{ fontWeight: "bold" }}>
                    {data[index].sacDaysToPrint} Days&nbsp;
                    </span>
                  ) : null}
                  <span style={{ fontWeight: 'bold' }}>Same As Cash Price (SAC):&nbsp;</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '17px' }}>
                  <span style={{ fontWeight: 'bold' }}>After SAC:&nbsp;</span>
                  <span><span>Purchase early to save</span> on the total cost.</span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '31px', fontSize: '17px' }}>
                  <span style={{ fontWeight: 'bold' }}>Total Cost: </span>
                  <span>

                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter.format(data[index].weeklyRate).replace(/^(\D+)/, '$1 ')
                      : '-'}{' '}
                    x{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : '-'}{' '}
                  </span>
                  <span>payments =</span>
                  <span>{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter.format(data[index].totalCost).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', marginBottom: '30px', marginTop: '21px' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : '-'}{'  '}
                    {/* Previously Rented */}
                  </p>
                  <div className="text-align:center;width:100%" style={{ fontSize: '12px' }}>
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span>Item: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Model: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : '-'}
                        {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Serial: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix} >
                    <Barcode width={1.5} value={generateBarCodeContent(data[index].inventoryPriceId, data[index].deptRefcode, data[index].subDeptRefcode)} displayValue={true} height={48} fontSize={7} />{' '}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else if (data[index]?.templateName == "VTSingleItemPriceTag_n") {
          return (
            <div style={{ fontFamily: 'Arial', marginTop: '0% important' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '25px', textAlign: 'center', paddingTop: index > 0 ? '20px' : '' }}>
                  {validateData(brand) ? brand : '-'}
                </h1>
                {
                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '26px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', display: 'inline-block' }}>
                      <span style={{ fontSize: '70px', fontWeight: 'bold', position: 'relative', top: '-44px' }}>$</span>
                      <span style={{ fontSize: '138px', fontWeight: 'bold' }}>
                        {validateData(SplitweeklyRate[0]) ? SplitweeklyRate[0] : ' - '}
                      </span>
                      <p style={{ margin: '0' }}>
                        WAS:$25.99, 77 Payments
                      </p>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: '8px' }}>
                      <p style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '10px', marginTop: '15px' }}>
                        {validateData(SplitweeklyRate[1]) ? SplitweeklyRate[1] : ' - '}
                      </p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>PER WEEK</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : '-'}{' '}
                        Payments</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>to Own It</p>
                    </div>
                  </div>
                </div>

                <p style={{ fontSize: '17px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '15px', marginBottom: '10px' }}>Additional Flexible Payment Options:</p>
                <div style={{ width: '100%', float: 'left', marginBottom: '15px', borderBottom: '1px solid #000' }}>
                  <div style={{ width: '33%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Bi-weekly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter.format(data[index].biWeeklyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? (data[index].biWeeklyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Semi-monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter.format(data[index].semiMonthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,
                      </span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? (data[index].semiMonthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter.format(data[index].monthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? (data[index].monthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%', marginBottom: '7px', fontSize: '16px' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Cash Price + Rent-to-Own Charge = Total Cost of OwnerShip&nbsp;</div>
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '33.3%', float: 'left' }}>{validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                      : '-'}</div>
                    <div style={{ width: '33.3%', float: 'left' }}> {validateData(data[index]) &&
                      validateData(data[index].costOfRental)
                      ? formatter.format(data[index].costOfRental).replace(/^(\D+)/, '$1 ')
                      : '-'}</div>
                    <div style={{ width: '33.3%', float: 'left' }}> {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter.format(data[index].totalCost).replace(/^(\D+)/, '$1 ')
                      : '-'}</div>
                  </div>
                </div>
                <div style={{ border: '1px solid #000', marginBottom: '0px', marginTop: '25px' }}></div>
                <p style={{ fontSize: '17px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '0px', marginBottom: '0px' }}>Easy Ownership Options:</p>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '0px', fontSize: '16px' }}>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) &&
					        Number(data[index].sacDaysToPrint) > 0 ? (
                    <span style={{ fontWeight: "bold" }}>
                    {data[index].sacDaysToPrint} Days&nbsp;
                    </span>
                  ) : null}
                  <span style={{ fontWeight: 'bold' }}>Same As Cash Price (SAC):&nbsp;</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '16px' }}>
                  <span style={{ fontWeight: 'bold' }}>After SAC:&nbsp;</span>
                  <span><span>Purchase early to</span> save on total cost of ownership</span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '0px', fontSize: '0px' }}>
                  <div style={{ border: '1px solid #000' }}></div>
                  <div style={{ textAlign: 'left' }}>
                    <span style={{ fontSize: '12px' }}>This is rent-to-own agreement. You will not own the merchandise until the total cost of ownership is paid in full or you exercise your early purchase option</span>
                  </div>
                </div>

                <div style={{ width: '100%', textAlign: 'center' }}>
                  {data[index]?.savingsAmount == undefined ?
                    <div>
                      <p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', marginBottom: '30px', marginTop: '20px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].status)
                          ? data[index].status
                          : '-'}{'  '}
                      </p>
                    </div> :
                    <div style={{ width: '100%', float: 'left', fontSize: '11px', display: 'flex', marginBottom: '3px' }}>
                      <div style={{ width: '83%', float: 'left', textAlign: 'right' }}>
                        <p style={{ marginTop: '20px', marginBottom: '0', marginRight: '30px' }}>
                          {validateData(data[index]) &&
                            validateData(data[index].status)
                            ? data[index].status
                            : '-'}{'  '} <span>{validateData(data[index]) &&
                              validateData(data[index].timesRented)
                              ? (data[index].timesRented)
                              : '-'}{' '} times</span>
                          {/* Previously Rented */}
                        </p>
                        <p style={{ fontSize: '20px', fontWeight: 'bold', margin: '0' }}>YOU SAVE{validateData(data[index]) &&
                          validateData(data[index].savingsAmount)
                          ? formatter.format(data[index].savingsAmount).replace(/^(\D+)/, ' $1 ')
                          : '-'}</p>
                        <p style={{ marginTop: '0', marginRight: '55px' }}>compared to new</p>
                      </div>
                      <div style={{ width: '35%', float: 'left', marginTop: '20px', textAlign: 'left', paddingLeft: '18%' }}>
                        <div>
                          Acquired
                        </div>
                        <div>by RAC on </div>
                        <div>01/07/2023</div>
                      </div>
                    </div>
                  }

                  <div className="text-align:center;width:100%" style={{ fontSize: '12px', marginTop: '-1px' }}>
                    {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span>Item: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Model: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : '-'}
                        {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span>Serial: </span>
                      <span>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix} style={{ marginTop: '-2px' }} >
                    <Barcode width={1.5} value={generateBarCodeContent(data[index].inventoryPriceId, data[index].deptRefcode, data[index].subDeptRefcode)} displayValue={true} height={48} fontSize={7} />{' '}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else if (data[index]?.templateName == 'WVSingleItemPriceTag_n') {
          return (
            <div
              key={index}
              style={{ fontFamily: "Arial", marginTop: "0% important" }}
            >
              <div style={{ width: "100%" }}>
                <h1
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    paddingTop: index > 0 ? '20px' : ''
                  }}
                >
                  {validateData(brand) ? brand : "-"}
                </h1>
                {
                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '26px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{ textAlign: "center", display: "inline-block" }}
                    >
                      <span
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          position: "relative",
                          top: "-44px",
                        }}
                      >
                        $
                      </span>
                      <span style={{ fontSize: "138px", fontWeight: "bold" }}>
                        {validateData(SplitweeklyRate[0])
                          ? SplitweeklyRate[0]
                          : " - "}
                      </span>
                    </div>
                    <div style={{ textAlign: "center", marginLeft: "8px" }}>
                      <p
                        style={{
                          fontSize: "70px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        {validateData(SplitweeklyRate[1])
                          ? SplitweeklyRate[1]
                          : " - "}
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        PER WEEK
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : "-"}{" "}
                        Payments
                      </p>
                      <p
                        style={{ margin: "0px 0px 5px 0px", fontSize: "16px" }}
                      >
                        to Own It
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "21px",
                    marginBottom: "8px",
                  }}
                >
                  Additional Flexible Payment Options:
                </p>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "15px" }}
                >
                  <div
                    style={{
                      width: "33%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Bi-weekly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter
                            .format(data[index].biWeeklyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? data[index].biWeeklyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "32%",
                      float: "left",
                      borderRight: "1px solid #000",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Semi-monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter
                            .format(data[index].semiMonthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? data[index].semiMonthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "32%", float: "left" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      Monthly
                    </p>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter
                            .format(data[index].monthlyRate)
                            .replace(/^(\D+)/, "$1 ")
                          : "-"}
                        ,
                      </span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? data[index].monthlyTerm
                          : "-"}{" "}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "18px",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "8px",
                    marginBottom: "4px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Rent to Own Charge:
                  </span>
                  <span>
                    {" "}
                    {validateData(data[index]) &&
                      validateData(data[index].costOfRental)
                      ? formatter
                        .format(data[index].costOfRental)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                </p>
                <p
                  style={{
                    fontSize: "19px",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    textAlign: "center",
                    marginTop: "4px",
                    marginBottom: "4px",
                  }}
                >
                  Easy Ownership Options:
                </p>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "7px",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Retain Value:&nbsp;
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter
                        .format(data[index].sameAsCashPrice)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    &nbsp;Same As Cash (SAC)
                  </span>
                </div>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) && Number(data[index].sacDaysToPrint) > 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                     // marginBottom: "7px",
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {data[index].sacDaysToPrint}&nbsp;
                    </span>
					          <span style={{ fontWeight: "bold" }}>
                      Days SAC
                    </span>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "7px",
                    fontSize: "17px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>After SAC:&nbsp;</span>
                  <span>
                    <span>Purchase early to save on the total cost </span>
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: "33px",
                    fontSize: "15px",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Total Cost of All Payments:{" "}
                  </span>
                  <span>
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter
                        .format(data[index].weeklyRate)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}{" "}
                    x{" "}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : "-"}{" "}
                  </span>
                  <span>payments =</span>
                  <span>
                    {" "}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter
                        .format(data[index].totalCost)
                        .replace(/^(\D+)/, "$1 ")
                      : "-"}
                  </span>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                      fontWeight: "bold",
                      marginBottom: "30px",
                      marginTop: "15px",
                    }}
                  >
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : "-"}
                    {"  "}
                    {/* Previously Rented */}
                  </p>
                  <div className="text-align:center;width:100%" style={{ fontSize: '12px' }}>
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: "12px" }}>Item: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Model: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : "-"}
                        {"  "}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: "12px" }}>Serial: </span>
                      <span style={{ fontSize: "12px" }}>
                        {" "}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : "-"}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix}>
                    <Barcode width={1.5}
                      value={generateBarCodeContent(
                        data[index].inventoryPriceId,
                        data[index].deptRefcode,
                        data[index].subDeptRefcode
                      )}
                      displayValue={true}
                      height={48}
                      fontSize={7}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else {
          return (

            <div key={index} style={{ fontFamily: 'Arial', marginTop: '0% important' }}>
              <div style={{ width: '100%' }}>
                <h1 style={{ fontSize: '25px', textAlign: 'center', paddingTop: index > 0 ? '20px' : '' }}>
                  {validateData(brand) ? brand : '-'}
                </h1>
                {
                  data[index]?.inventoryDescription?.length <= 62 ?
                    <p style={{ textAlign: 'center', marginBottom: '26px', fontSize: '15px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p> :
                    <p style={{ textAlign: 'center', marginBottom: '8px', fontSize: '13px' }}>
                      {validateData(data[index]) &&
                        validateData(data[index]?.inventoryDescription)
                        ? data[index].inventoryDescription
                        : '-'}
                    </p>}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center', display: 'inline-block' }}>
                      <span style={{ fontSize: '70px', fontWeight: 'bold', position: 'relative', top: '-44px' }}>$</span>
                      <span style={{ fontSize: '138px', fontWeight: 'bold' }}>
                        {validateData(SplitweeklyRate[0]) ? SplitweeklyRate[0] : ' - '}
                      </span>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: '8px' }}>
                      <p style={{ fontSize: '70px', fontWeight: 'bold', marginBottom: '10px', marginTop: '15px' }}>
                        {validateData(SplitweeklyRate[1]) ? SplitweeklyRate[1] : ' - '}
                      </p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>PER WEEK</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].weeklyTerm)
                          ? data[index].weeklyTerm
                          : '-'}{' '}
                        Payments</p>
                      <p style={{ margin: '0px 0px 5px 0px', fontSize: '16px' }}>to Own It</p>
                    </div>
                  </div>
                </div>

                <p style={{ fontSize: '21px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '24px', marginBottom: '10px' }}>Additional Flexible Payment Options:</p>
                <div style={{ width: '100%', float: 'left', marginBottom: '15px' }}>
                  <div style={{ width: '33%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Bi-weekly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyRate)
                          ? formatter.format(data[index].biWeeklyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].biWeeklyTerm)
                          ? (data[index].biWeeklyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left', borderRight: '1px solid #000' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Semi-monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyRate)
                          ? formatter.format(data[index].semiMonthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,
                      </span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].semiMonthlyTerm)
                          ? (data[index].semiMonthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '32%', float: 'left' }}>
                    <p style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: '10px', fontSize: '16px' }}>Monthly</p>
                    <div style={{ width: '100%', float: 'left', textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyRate)
                          ? formatter.format(data[index].monthlyRate).replace(/^(\D+)/, '$1 ')
                          : '-'}
                        ,</span>
                      <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                        {validateData(data[index]) &&
                          validateData(data[index].monthlyTerm)
                          ? (data[index].monthlyTerm)
                          : '-'}{' '}
                        Payments
                      </span>
                    </div>
                  </div>
                </div>
                <p style={{ fontSize: '22px', fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginTop: '10px', marginBottom: '6px' }}>Easy Ownership Options:</p>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '18px' }}>
                {validateData(data[index]) && validateData(data[index].sacDaysToPrint) &&
					        Number(data[index].sacDaysToPrint) > 0 ? (
                    <span style={{ fontWeight: "bold" }}>
                    {data[index].sacDaysToPrint} Days&nbsp;
                    </span>
                  ) : null}
                  <span style={{ fontWeight: 'bold' }}>Same As Cash Price (SAC):&nbsp;</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].sameAsCashPrice)
                      ? formatter.format(data[index].sameAsCashPrice).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '7px', fontSize: '18px' }}>
                  <span style={{ fontWeight: 'bold' }}>After SAC:&nbsp;</span>
                  <span><span style={{ fontWeight: 'bold' }}>{data[index].epoPercentage} % </span> off remaining total</span>
                </div>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '42px', fontSize: '18px' }}>
                  <span style={{ fontWeight: 'bold' }}>Total Cost: </span>
                  <span>

                    {validateData(data[index]) &&
                      validateData(data[index].weeklyRate)
                      ? formatter.format(data[index].weeklyRate).replace(/^(\D+)/, '$1 ')
                      : '-'}{' '}
                    x{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].weeklyTerm)
                      ? data[index].weeklyTerm
                      : '-'}{' '}
                  </span>
                  <span>payments =</span>
                  <span>{' '}
                    {validateData(data[index]) &&
                      validateData(data[index].totalCost)
                      ? formatter.format(data[index].totalCost).replace(/^(\D+)/, '$1 ')
                      : '-'}
                  </span>
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', marginBottom: '30px', marginTop: '26px' }}>
                    {validateData(data[index]) &&
                      validateData(data[index].status)
                      ? data[index].status
                      : '-'}{'  '}
                    {/* Previously Rented */}
                  </p>
                  <div className="text-align:center;width:100%">
                  {validateData(data[index]) && validateData(data[index].weeklyRateAddOn) &&
                      Number(data[index].weeklyRateAddOn) > 0 ? (
                        <span style={{ fontSize: '12px' }}>
                          {data[index].weeklyRateAddOn} -&nbsp;
                        </span>
                      ) : null}
                    <span>
                      <span style={{ fontSize: '12px' }}>Item: </span>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].inventoryNumber)
                          ? data[index].inventoryNumber
                          : '-'}{'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: '12px' }}>Model: </span>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].model)
                          ? data[index].model
                          : '-'}
                        {'  '}
                      </span>
                    </span>
                    <span>-</span>
                    <span>
                      <span style={{ fontSize: '12px' }}>Serial: </span>
                      <span style={{ fontSize: '12px' }}>
                        {' '}
                        {validateData(data[index]) &&
                          validateData(data[index].serialNumber)
                          ? data[index].serialNumber
                          : '-'}
                      </span>
                    </span>
                  </div>
                  <div className={formClasses.barcodeSizeFix} >
                    <Barcode width={1.5} value={generateBarCodeContent(data[index].inventoryPriceId, data[index].deptRefcode, data[index].subDeptRefcode)} displayValue={true} height={48} fontSize={7} format='CODE128' />{' '}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    });
  };

  return <div>{PriceTag()}</div>;
};

const generateBarCodeContent = (invPriceId: any, depRefCode: any, SubDepRefCode: any) => {
  if (invPriceId && depRefCode && SubDepRefCode) {
    const BAR_CODE_FORMAT = "%-18s%1s%3s%3s";
    return sprintf(BAR_CODE_FORMAT, invPriceId, "P", depRefCode, SubDepRefCode);
  }
  else if (invPriceId && depRefCode) {
    const BAR_CODE_FORMAT = "%-18s%1s%3s%3s";
    return sprintf(BAR_CODE_FORMAT, invPriceId, "P", depRefCode, depRefCode);
  }
  else if (invPriceId) {
    return `${invPriceId}     P`;
  }
  return '';
}

// export default PrintFunction
